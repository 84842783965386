import Axios from 'axios';
import moment from 'moment';
import { mask } from 'vue-the-mask';
// import * as hiringvalue from './../../services/hiringvalue';

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      company: {},
      hiring: {
        tasks: '',
        company: {},
        user: {},
        files: [],
        invoices: [],
        specialities: [],
      },
      community: {
        users: [],
      },
      formSelectProvider: {
        provider: 0,
        decision: 0,
      },
      isPaymentDate: false,
      formInfo: {
        razao: '',
        cnpj: '',
      },
      specialities: [],
      hasReviewedFlag: false,
      syncing: false,
      briefing: {},
      briefings: {},
      company: {},
      users: [],
      specialities:[],
      creators: ''
    }
  },

  mounted() {
    axios
    .get('/api/v1/squads?squad_id=' + this.$route.params.id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        var providers = [];
        var specialities = []

        response.data[0].hirings[0].specialities.forEach(speciality => {
            this.specialities.push(speciality)
        });
        response.data[0].hirings.forEach(hiring => {
          this.users.push(hiring.provider)
        });

        this.briefing = response.data[0].hirings[0]
        this.briefings = response.data[0].hirings
      } else {
        console.log(response.data.error);
      }
    });

    axios
    .get('/api/v1/companies/' + this.me.company_id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.company = response.data.data;
      } else {
        console.log(response.data.error);
      }
  });
  },

  computed: {
    // acceptedvalue() {
    //   console.log(hiringvalue, this.hiring);
    //   let aux = hiringvalue.company(this.hiring.company, this.hiring);
    //   return aux;
    // },

    chatUrl() {
      if (this.hiring.status < 3) return '#';
      else return '/dashboard/client/briefing/' + this.hiring.id + '/chat/' + this.hiring.provider_id;
    },

    projectName() {
      return '#' + this.hiring.id + '-' + moment(this.hiring.created_at).format('Y');
    },

    isCompleted() {
      if (this.hiring.status != 4) return false;
      if (moment(this.hiring.date_end).subtract(5, "days") >= moment()) return false;
      // if (!this.hiring.invoices || !this.hiring.invoices.length) return false;
      // this.hiring.invoices.forEach(invoice => {
      //   if (invoice.status != 2) return false;
      // });
      return true;
    },

    canEvaluate() {
      if (this.hiring.status != 4) return false;
      if (moment(this.hiring.date_end) >= moment()) return false;
      if (this.haveReviewed) return false;
      return true;
    },

    haveReviewed() {
      if (!this.hiring.reviews) return false;
      let haveReviewed = this.hiring.reviews.map((e) => { return e.user_id }).indexOf(this.me.id);
      if (haveReviewed > -1) return true;
      if (this.hasReviewedFlag) return true;
      return false;
    },

    reviewDate() {
      if (!this.haveReviewed) return moment();
      let review = {};
      let index = this.hiring.reviews.map((e) => { return e.user_id }).indexOf(this.me.id);
      if (index > -1) review = this.hiring.reviews[index];
      return review.created_at;
    },

    communityIds() {
      var ids = [];
      if (this.community.users.length) ids = this.community.users.map((e) => { return e.id });
      // console.log(ids);
      return ids;
    },
  },

  methods: {
    formatDate(date) {
      return (date) ? moment(date).format('DD/MM/YYYY') : 'Renovação';
    },

    toCurrency(val) {
      let value = val * 1;
      let currency = "BRL";
      if (this.hiring.company.currency == "eur") {
        value = val / this.$store.state.currencies.eur;
        currency = "EUR";
      }
      if (this.hiring.company.currency == "usd") {
        value = val / this.$store.state.currencies.usd;
        currency = "USD";
      }
      return (value / 100).toLocaleString("pt-BR", { style: "currency", currency: currency, minimumFractionDigits: 2 });
    },

    costByPlan(value, plan) {
      switch (plan) {
        case 1:
          value = value / 0.85;
          break;
        case 2:
          value = value / 0.85;
          break;
        case 3:
          value = value / 0.75;
          break;
        case 4:
            value = value / 0.8928571429;
            break;
        case 5:
            value = value;
            break;
        case 6:
            value = value / 0.70;
            break;
        case 7:
            value = value / 0.80;
            break;
        default:
          value = value / 0.85;
      }
      // Pernod Ricard tem 0,6% sobre o valor final
      if (this.hiring.company_id == 159) value = value * 1.006;
      return value;
    },

    approve(ids) {
      // check if user has financial data
      let _this = this;
      if (!this.formInfo.razao || !this.formInfo.cnpj) {
        $('#info-modal').modal('show');
        return;
      }
      var data = {
        providers: ids,
        decision: 1,
      };
      _this.syncing = true;
      axios
        .put('/api/v1/hirings/' + this.hiring.id + '/select-professional', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          _this.syncing = false;
          if (!response.data.error) {
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    giveupContract() {
      var data = {};
      axios
        .put('/api/v1/hirings/' + this.hiring.id + '/giveup-contract', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            $('#cancel-contract-modal').modal('hide');
            this.$router.push({ path: '/dashboard/client' });
          } else {
            console.log(response.data.error);
          }
        });
    },

    evaluate(formData) {
      var data = formData;
      try {
        axios
          .post('/api/v1/reviews?hiring=' + this.hiring.id, data, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            if (!response.data.error) {
              this.$router.push({ path: '/dashboard/client/' });
            } else {
              console.log(response.data.error);
            }
          });
        this.hiring.reviews = [{ ...data, user_id: this.me.id }]
        this.hasReviewedFlag = true
      } catch (err) {
        console.log(err)
      }
    },

    renew(formData) {
      var _this = this;
      var data = {
        date_end: formData.date_end,
        date_start: formData.date_start,
        extra_value: parseInt(formData.value.replace(/\D/g, '')),
        message: formData.message,
      };
      axios
        .post('/api/v1/hirings/' + _this.hiring.id + '/renew', data, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.$router.push({ path: '/dashboard/client/' });
          } else {
            console.log(response.data.error);
          }
        });
    },

    submitInfo() {
      let _this = this;
      let formData = new FormData();
      formData.set("razao", _this.formInfo.razao);
      formData.set("cnpj", _this.formInfo.cnpj);

      formData.append('_method', 'PUT');
      axios
        .post('/api/v1/companies/' + _this.formInfo.id, formData, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          // console.log(response.data);
          if (response.data.error) {
            _this.errors = response.data.error;
          } else {
            $('#info-modal').modal('hide');
          }
        });
    },

    editHiring(data) {
      axios
        .put('/api/v1/hirings/' + data.id, data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
          } else {
            console.log(response.data.error);
          }
        });
    },

    equipament(value){
      if(value == 0){
        return 'Equipamento propio'
      } else{
        return 'Equipamento fornecido'
      }
    },

    status(id){
      var text
      if(id == 1){
        text = 'In progress'
      }
      return text
    }
  },

}
